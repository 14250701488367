import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, IconButton, Typography, withStyles} from "@material-ui/core";

// Shared components
import {SearchInput} from "components";

// Component styles
import styles from "./styles";
import Grid from "@material-ui/core/Grid";
import CreateDriver from "../CreateDriver/CreateDriver";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {CloseOutlined as CloseButton} from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            right: false
        };

        this.onSearch = this.onSearch.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            searchString: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    toggleDrawer = (anchor, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        this.setState({
            [anchor]: open
        });
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        const exportCSV = () => {
            const csvRow = [];
            const A = [
                ["Sr. no", "Id", "Licence Image", "License Number", "Partner", "User"]
            ];
            const allDriverData = this.props.allDrivers;

            let firstNameForFileName = "";
            let lastNameForFileName = "";

            for (let item = 0; item < allDriverData.length; item++) {
                A.push([
                    item,
                    allDriverData[item].id,
                    allDriverData[item].license_image,
                    allDriverData[item].license_number,
                    allDriverData[item].partner,
                    allDriverData[item].user
                ]);
                firstNameForFileName = allDriverData[0].id;
                lastNameForFileName = allDriverData[allDriverData.length - 1].id;
            }

            for (let i = 0; i < A.length; ++i) {
                csvRow.push(A[i].join(","));
            }

            const today = new Date();
            const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            const time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + "_" + time;

            const csvString = csvRow.join("%0A");

            const a = document.createElement("a");

            a.href = "data:attachement/csv," + csvString;

            a.target = "_Blank";

            a.download =
                "driver_list_" +
                firstNameForFileName +
                "_to_" +
                lastNameForFileName +
                "at" +
                dateTime +
                ".csv";

            document.body.appendChild(a);

            a.click();
        };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Operators
                    </Typography>
                </div>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                </div>
                {/* <div className={classes.row}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search by Partner Name, username or vehicle"
                        getsearchterm={this.onSearch}
                    />
                    <span className={classes.spacer}/>
                    <Button
                        className={classes.exportButton}
                        size="small"
                        variant="outlined"
                        onClick={this.toggleDrawer('right', true)}
                    >
                        Add
                    </Button>
                    <Button
                        className={classes.exportButton}
                        size="small"
                        variant="outlined"
                        onClick={exportCSV}
                    >
                        Export
                    </Button>
                </div> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search by Partner Name, username or vehicle"
                            getsearchterm={this.onSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} align="right">
                        <div>
                            <Button
                                className={classes.exportButton}
                                size="small"
                                variant="outlined"
                                onClick={this.toggleDrawer("right", true)}
                                disabled={secureLocalStorage.getItem("access_type") === false}
                            >
                                Add
                            </Button>
                            {(secureLocalStorage.getItem("id") === 1454) && (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={exportCSV}
                                    style={{ marginRight: 12 }}
                                >
                                    Export
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>

                <SwipeableDrawer
                    anchor={"right"}
                    open={this.state.right}
                    onClose={this.toggleDrawer("right", false)}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerWidth
                    }}
                    ModalProps={{
                        BackdropProps: {
                            classes: {
                                root: classes.BackdropProps
                            }
                        }
                    }}
                >
                    <Grid container>
                        <Grid item xs={3}>
                            <img
                                alt="add_driver_logo"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    margin: "10px",
                                    borderRadius: "50px"
                                }}
                                src={"/images/transport.png"}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                className={classes.nameText}
                                variant="h3"
                                style={{
                                    marginTop: "15%",
                                    marginLeft: "14px",
                                    color: "#808080"
                                }}
                            >
                                Add Operator
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                style={{marginTop: "10%"}}
                                onClick={this.toggleDrawer("right", false)}
                            >
                                <CloseButton/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <CreateDriver
                        pageLoad={this.props.reloadRequest}
                        onClose={this.toggleDrawer("right", false)}
                    />
                </SwipeableDrawer>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
